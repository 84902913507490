<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-08-25 08:49:57
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <div class="content">
    <div class="title">使用條款</div>
    <div class="main html-view" v-html="html"></div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  data() {
    return {
      html: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.pages({ id: 2 }).then((res) => {
        if (res.code == 200) {
          this.html = res.data.content;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 100px 0;
  .title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 24px;
  }
  .main {
    width: 1000px;
    margin: 0 auto;
  }
}
</style>